body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bonk {
  background-color:#0D0B08;
  color: #E6E6E6;
}

.bonk a, .bonk a:link, .bonk a:visited, .bonk a:hover, .bonk a:active {
  color: #E6E6E6;
}

.zac {
  background-color:#CD0000;
  color: #FFD900;
}

.zac a, .zac a:link, .zac a:visited, .zac a:hover, .zac a:active {
  color: #FFD900;
}

.gnarp {
  background-color:#0D0B08;
  color: #E6E6E6;
}

.gnarp a, .gnarp a:link, .gnarp a:visited, .gnarp a:hover, .gnarp a:active {
  color: #E6E6E6;
}

.bamse {
  background-color:#0D0B08;
  color: #E6E6E6;
}

.bamse a, .bamse a:link, .bamse a:visited, .bamse a:hover, .bamse a:active {
  color: #E6E6E6;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25vh;
}

.Splash {
  display: flex;
  align-items: center;
}

.Splash .ImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1vw;
}

.Splash > span {
  font-size: 10vw;
}

.Splash img {
  width: 20vw;
}

.Splash.Started > span {
  font-size: 6vw;
}

.Splash.Started img {
  width: 12vw;
}

.Shuffler {
  margin-top: 3vw;
  font-size: 3vw;
  width: 100vw;
  padding-left: 15vw;
  padding-right: 15vw;
  display: flex;
  justify-content: center;
}

.Complain {
  margin-top: 3vh;
  font-size: 2vw;
}
